<template>
<div :value="modelValue" @input="updateProdSkus">
     <b-row v-for="(sku,index) in modelValue" :key="index">
      <b-row class="d-flex align-items-center justify-content-center p-3">
        <b-col style="min-width:250px;">
          <strong>SKU</strong>
          <b-form-select :id="'sku-input'+index"
              v-model="sku.sku"
              :options="filteredSkuOptions">
          </b-form-select>
          <small>Select SKU</small>
        </b-col>

        <b-col>
          <strong>Distribution</strong>
          <b-form-input :id="'distribution-input'+index"
            type="number"
            :value="sku.distribution"
            v-model="sku.distribution"
            min="1"
            max="100">
          </b-form-input>
          <small>Insert distribution %</small>
        </b-col>
        <b-button :id="'delete-sku-button-'+index"
                  v-b-tooltip.hover
                  title="Delete sku"
                  size="sm"
                  variant="danger"
                  style="min-width: 0px; height: 40px;"
                  @click="deleteSkuDistribution(sku)">
          <feather type="trash-2"></feather>
        </b-button>
      </b-row>
    </b-row>
    <b-button variant="primary" id="add-sku-button" class="mt-3 mb-3"
    @click="addSkuDistribution()" style="min-width: 0px; height: 40px;">Add +</b-button>
</div>
</template>
<script>

export default {
  name: 'sku-distribution-list',
  props: {
    skuOptions: {
      type: Array,
      default: () => [],
    },
    productType: {
      type: String,
    },
    distributions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      skuOptionsFiltered: [],
      skuDistributionOptions: [],
      modelValue: [],
    };
  },
  methods: {
    addSkuDistribution() {
      this.modelValue.push({ id: Math.random(), sku: '', distribution: 1 });
    },
    async deleteSkuDistribution(skuDistribution) {
      const shouldDelete = await this.$bvModal.msgBoxConfirm('Are you sure to delete the sku distribution?');
      if (!shouldDelete) {
        return;
      }
      const newSkuDistributionList = this.modelValue.filter(sku => sku.id !== skuDistribution.id);
      this.modelValue = newSkuDistributionList;
      this.$emit('input', this.modelValue);
    },
    updateProdSkus() {
      this.$emit('input', this.modelValue);
    },
  },
  created() {
    this.distributions.forEach(element => {
      this.modelValue.push({ id: element.id, sku: element.sku, distribution: element.distribution });
    });
  },
  computed: {
    filteredSkuOptions() {
      return this.skuOptions.filter(item => item.product_type === this.productType);
    },
  },
};
</script>
