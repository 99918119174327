<template>
  <div>
    <b-spinner v-if="loading"></b-spinner>
    <b-card v-if="!loading" class="mb-4 border-0">
      <template #header>
        <strong v-if="!isEditionOperation()">Add exception</strong>
        <strong v-else>Edit exception</strong>
        <div class="info-button-wrapper float-right">
          <div id="info-default-configs" v-b-hover="handleInfoHover">
            <b-icon v-if="isHovered" icon="info-circle-fill" variant="info" scale="2"></b-icon>
            <b-icon v-else icon="info-circle" scale="2"></b-icon>
          </div>
          <b-tooltip
            id="info-default-configs-tooltip"
            target="info-default-configs"
            triggers="hover"
            placement="left"
            custom-class="dark-tooltip">
            Here you can configure SKU exceptions for specific clients.
            In case you want to send a specific SKU to members of a specific client you can do it here.
            Select first a Product type in order to configure the following fields.
          </b-tooltip>
        </div>
      </template>
      <div class="exception-holder">
        <b-row class="mb-4">
          <div class="exception-col">
            <b-form-group
              id="input-group-product-type"
              label="Product type *"
              class="exception-label"
              description="Select a product type in order to select the SKU next"
              invalid-feedback="Product type is required"
              required
              :state="isValid('exception.productType')">
                <b-form-select v-model="exception.productType"
                    class="exception-select"
                    :options="productTypeOptions"
                    v-on:change="loadSkuOptions()">
                </b-form-select>
            </b-form-group>
          </div>
        </b-row>
        <b-row class="mb-4">
           <b-form-group
              id="input-group-sku-distribution"
              label="SKU Distribution *"
              class="exception-label"
              description="Add/edit SKU distributions (Weight in % to be chosen for the product type selected)"
              invalid-feedback="At least 1 SKU distribution is required"
              required
              :state="isValid('exception.skuDistribution')">
             <skuDistributionList v-if="isEditionOperation()"
               v-model="exception.productTypeSkus"
               :skuOptions="skuOptions"
               :distributions.sync="exception.productTypeSkus"
               :productType="exception.productType">
             </skuDistributionList>
              <skuDistributionList v-else
                v-model="exception.productTypeSkus"
                :skuOptions="skuOptions"
                :productType="exception.productType">
              </skuDistributionList>
            </b-form-group>
        </b-row>
        <b-row class="mb-4">
          <div class="exception-col">
             <b-form-group
              id="input-group-clients"
              label="Clients *"
              class="exception-label clients-exceptions-group"
              description="Insert here the clients you want to configure this exception for. Start writing and click enter to add it."
              invalid-feedback="Clients are required"
              required
              :state="isValid('exception.clients')">
                <client-multi-select :config="multiClientsConfig"
                                  :selected-value="exception.clients"
                                  @selection-change="onClientFilterSelectionChange"></client-multi-select>
            </b-form-group>
          </div>
        </b-row>
        <b-row class="mb-4">
          <div class="exception-col">
             <b-form-group
              id="input-group-short-description"
              label="Short Description"
              class="exception-label clients-exceptions-description-group"
              description="You can add a description to your exception. Not required"
              invalid-feedback="Clients are required">
                <b-form-input
                  type="text"
                  maxlength=254
                  v-model="exception.description"
                  placeholder="Short Description"
                  class="exception-textbox"></b-form-input>
            </b-form-group>
          </div>
        </b-row>
        <b-row class="mt-3" style="margin-top:0 !important">
          <b-col class="d-flex align-items-center justify-content-end mr-4 btn-save-exception">
            <b-button v-if="!isEditionOperation()"
                      class="ml-3"
                      @click="saveException"
                      type="button"
                      :disabled="saving"
                      variant="primary">
              <b-spinner label="Loading..." variant="light" small v-if="saving"></b-spinner>
              <span v-else>Save SKU exception</span>
            </b-button>
            <b-button v-else
                      class="ml-3"
                      @click="editException"
                      type="button"
                      :disabled="saving"
                      variant="primary">
              <b-spinner label="Loading..." variant="light" small v-if="saving"></b-spinner>
              <span v-else>Edit SKU exception</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

import ClientMultiSelect from '@/components/Clients/ClientMultiSelect.vue';
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import skuDistributionList from '@/components/OrderManagement/SkuConfigs/SKUDistributionList.vue';

export default {
  name: 'client-exception-configurations',
  async created() {
    this.loading = true;
    if (this.isEditionOperation()) {
      await this.getEditable();
      await this.fetchClientBatches(this.exception.clientIds);
    }
    await this.loadSkuOptions();

    this.loading = false;
  },
  data() {
    return {
      exception: {
        productType: '',
        sku: '',
        clients: [],
        description: '',
        productTypeSkus: [],
      },
      productTypeOptions: [],
      skuOptions: [],
      skuOptionsFiltered: [],
      isHovered: false,
      saving: false,
      loading: true,
      clientIds: [],
      batchSizeLimit: 50,
      multiClientsConfig: {
        multiple: true,
        limit: 3,
      },
    };
  },
  computed: {
    rules() {
      return {
        exception: {
          productType: { required },
          sku: {},
          clients: { required },
          description: '',
          productTypeSkus: { required },
        },
      };
    },
  },
  validations() {
    return this.rules;
  },
  mounted() {
    this.triggerSkuChange();
  },
  beforeMount() {
    this.loadProductTypes();
  },
  components: {
    ClientMultiSelect,
    skuDistributionList,
  },
  methods: {
    getDistributions(dists) {
      const parsedDistributions = [];
      for (let i = 0; i < dists.length; i++) {
        parsedDistributions.push(dists[i].product_type_sku);
      }
      return parsedDistributions;
    },
    async getEditable() {
      await axios
        .get(`v1/skus/exceptions/${this.$route.params.id}`)
        .then(response => {
          if (response.data.length === 0) {
            this.$noty.error('Unable to load exception');
            return;
          }
          this.exception.productType = response.data.exception.product_type;
          this.exception.sku = response.data.exception.sku;
          this.exception.description = response.data.exception.description;
          this.exception.clientIds = response.data.exception.client_ids;
          this.exception.clients = []; // this will be filled by fetchClientBatches
          this.exception.productTypeSkus = this.getDistributions(response.data.exception.product_type_sku_exceptions);
        })
        .catch(response => {
          this.$noty.error('Unable to load exception');
          console.error(response);
        });
    },
    handleInfoHover(hovered) {
      this.isHovered = hovered;
    },
    getValidator(field) {
      const parts = field.split('.');
      let rootValidator = this.$v;

      parts.forEach(part => {
        rootValidator = rootValidator[part] || {};
      });
      return rootValidator;
    },
    isValid(field) {
      const validator = this.getValidator(field);
      const invalid = validator.$invalid;
      const value = validator.$model;

      if (!value && !('required' in validator)) {
        return true;
      }

      return !invalid;
    },
    onClientFilterSelectionChange(clients) {
      this.exception.clients = clients;
    },
    triggerSkuChange() {
      this.skuOptionsFiltered = this.skuOptions.filter(item => item.product_type === this.exception.productType);
      if (this.skuOptionsFiltered[0]) {
        this.exception.sku = this.skuOptionsFiltered[0].value;
      }
    },
    async loadSkuOptions() {
      if (!this.exception.productType) {
        return;
      }

      const params = {
        offset: 0,
        limit: 100,
        product_type: this.exception.productType,
        sort_order: 'asc',
        sort_by: 'name',
      };

      const response = await this.$store.dispatch('OrderManagement/getSkus', params);
      if (!response || parseInt(response.total, 10) < 1) {
        return;
      }

      const { skus } = response;

      this.skuOptions = [];
      skus.forEach(p => {
        this.skuOptions.push({
          product_type: this.exception.productType,
          text: `${p.name} (${p.stock})`,
          value: p.name,
        });
      });

      // set default values onload
      this.skuOptionsFiltered = this.skuOptions;
      this.exception.sku = this.skuOptions[0].value;
    },
    async fetchClientBatches(allIds) {
      if (!allIds || allIds.length === 0) return;
      const batches = [];

      for (let i = 0; i < allIds.length; i += this.batchSizeLimit) {
        batches.push(allIds.slice(i, i + this.batchSizeLimit));
      }

      const fetchPromises = batches.map(batch => this.fetchClientsByIds(batch));

      await Promise.all(fetchPromises);
    },
    async fetchClientsByIds(clientIDs) {
      if (!clientIDs || clientIDs.length === 0) return;

      const urlSearchParams = new URLSearchParams();
      clientIDs.forEach(clientID => urlSearchParams.append('id', clientID));
      urlSearchParams.append('limit', clientIDs.length);
      urlSearchParams.append('offset', 0);

      this.loading = true;
      await axios.get(`v2/clients?${urlSearchParams.toString()}`)
        .then(response => {
          response.data.items.forEach(client => {
            this.exception.clients.push({
              value: client.id,
              text: `${client.display_name} (id:${client.id})`,
            });
          });
        }).catch(response => {
          this.$noty.error('Unable to load clients');
          console.error(response);
        }).finally(() => { this.loading = false; });
    },
    async reloadFileConfigs() {
      this.exception = [];
      this.skuOptions = [];
      this.skuOptionsFiltered = [];
      await this.loadSkuOptions();
    },
    validateSkuDistributions() {
      const skuOptions = [];
      const distributionParsed = this.exception.productTypeSkus.map(option => ({
        id: option.id,
        sku: option.sku,
        distribution: parseFloat(option.distribution),
      }));

      const zeroValuedDistributions = distributionParsed.some(d => d.distribution === 0);
      if (zeroValuedDistributions) {
        this.$noty.error('Distributions cannot be configured with a value of zero');
        return [];
      }

      const distributionOverall = distributionParsed
        .reduce((skuDistributionSum, option) => skuOptions.push(option.sku) && skuDistributionSum + option.distribution, 0);

      const uniqueSkus = [ ...new Set(skuOptions) ];

      if (uniqueSkus.length !== this.exception.productTypeSkus.length) {
        this.$noty.error('Can not have more than one distribution % configuration for the same sku');
        return [];
      }

      if (distributionOverall !== 100) {
        this.$noty.error('To save distribution % the sum of all SKU distributions must be 100%');
        return [];
      }

      return distributionParsed;
    },
    async saveException() {
      if (this.$v.$invalid) {
        this.$noty.error('Please, check all required fields.');
        return;
      }
      const SKUDistributions = this.validateSkuDistributions();
      if (SKUDistributions.length === 0) return;

      const clientIds = [ ...new Set(this.exception.clients.map(item => item.value)) ];
      const params = {
        product_type: this.exception.productType,
        sku: this.exception.sku,
        client_ids: clientIds,
        description: this.exception.description,
        product_type_skus: this.exception.productTypeSkus,
      };

      this.saving = true;
      try {
        await axios.post('v1/skus/exception', params);
        await this.reloadFileConfigs();
        this.$noty.success('Exception saved');
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      } catch (error) {
        this.$noty.error('Unable to save client exception');
        console.error('error saving: ', error);
      } finally {
        this.saving = false;
      }
    },
    async editException() {
      if (this.$v.$invalid) {
        this.$noty.error('Please, check all required fields.');
        return;
      }
      const SKUDistributions = this.validateSkuDistributions();
      if (SKUDistributions.length === 0) return;

      const clientIds = [ ...new Set(this.exception.clients.map(item => item.value)) ];
      const params = {
        exception_id: this.$route.params.id,
        product_type: this.exception.productType,
        sku: this.exception.sku,
        client_ids: clientIds,
        description: this.exception.description,
        product_type_skus: this.exception.productTypeSkus,
      };

      this.saving = true;
      try {
        await axios.put(`v1/skus/exceptions/${this.$route.params.id}`, params);
        await this.reloadFileConfigs();
        this.$noty.success('Exception saved');
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      } catch (error) {
        this.$noty.error('Unable to save client exception');
        console.error('error saving: ', error);
      } finally {
        this.saving = false;
      }
    },
    isEditionOperation() {
      return this.$route.params.id !== undefined;
    },
    async loadProductTypes() {
      await axios
        .get('v1/skus/defaults')
        .then(response => {
          this.productTypeOptions = response.data.map((productType, index) => ({
            text: productType.productTypeName,
            value: productType.productTypeKey,
            selected: index === 0,
          }));
        })
        .catch(() => {
          this.$noty.error('Unable to load product types');
        });
    },
  },
};
</script>
