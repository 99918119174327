<template>
  <div class="p-3 sku-exceptions-holder">
    <header class="mb-4">
      <BackButton class="exception-BackButton"></BackButton>
      <h3 v-if="!isEditionOperation()">Add exception</h3>
      <h3 v-else>Edit exception {{ $route.params.id }}</h3>
    </header>
    <client-exception-config></client-exception-config>
  </div>
</template>

<script>

import ClientExceptionConfig from '../../../../components/OrderManagement/SkuConfigs/ClientSkuExceptionConfig.vue';
import BackButton from '../../../../components/BackButton.vue';

export default {
  name: 'EditClientExceptions.vue',
  components: {
    'client-exception-config': ClientExceptionConfig,
    BackButton,
  },
  methods: {
    isEditionOperation() {
      return !!this.$route.params.id;
    },
  },
};
</script>

<style lang="scss">
  @import '../../../../styles/OrderManagement.scss';
</style>
